import Layout from "../../Component/Layout";
import { Box } from "@mui/system";
import { Api } from "../../api/Api";
import { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PersonIcon from '@mui/icons-material/Person';
import AccountBoxIcon from '@mui/icons-material/AccountBox';


const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState([]);
  const getData = async () => {
    const data = await Api.DashboardData("admin/dashboard");
    console.log(data.data);
    setDashboardData(data.data)
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      <Layout>
        <Box sx={{ color: "#fff" }} >
          <Grid container spacing={4} p={4} sm={12} sx={{ maxHeight: '20%' }}>
            {dashboardData?.statistics?.map((i ,index) => {
              return (
                <Grid item sm={4} key={index} >
                  <Paper sx={{ backgroundColor: "#272E42", color: "white" ,display:"flex", alignItems:'center',height:"100%" }}>
                   {
                    i.title === "Total Organizations" ?
                    <AccountTreeIcon sx={{fontSize:'70px', margin:"4%"}}/> :
                    i.title === "Total Users" ?
                    <PersonIcon sx={{fontSize:'70px', margin:"4%"}}/> : <AccountBoxIcon sx={{fontSize:'70px', margin:"4%"}}/>
                   }
                    <Box p={3} >
                      <Typography variant='h6' sx={{ color: "#ffffff66", fontWeight: "bold", fontSize: '35px' }}>{i.value} </Typography><br />
                      <Typography variant='h5' sx={{ fontSize: '25px' }}>
                        {i.title}
                      </Typography><br />
                    </Box>
                  </Paper>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Layout>
    </>
  );
};
export default Dashboard;