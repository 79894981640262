import { config } from "./config";
const postRequest = async (path, body) => {
  try {
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    const res = await fetch(config.baseURL + path, params);
    const data = await res.text();
    console.log(data)
    if (
      JSON.parse(data).message === "Invalid token" ||
      JSON.parse(data).message === "Token expired"
    ) {
      localStorage.removeItem("user");
      localStorage.removeItem("userData");
      window.location.href = "/dashboard";
    }
    if(res.status===200 && JSON.parse(data).status===1){
      localStorage.setItem("user", JSON.stringify(JSON.parse(data).data))
    }
    return { statusCode: res.status, data };
  } catch (e) {
    console.log(`error in post Request (${path}) :- `, e);
    const data = null;
    return { statusCode: null, data };
  }
};
const listUsersRequest = async (path) =>{
  const userData = localStorage.getItem('user')&&JSON.parse(localStorage.getItem('user'))
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + userData.accessToken
    },
  };
  const res = await fetch(config.baseURL + path,params);
  const data = await res.json();
  return { statusCode: res.status, data };
}
// const getUserPlanDetails = async(path,body)=>{
//   const userData = localStorage.getItem('user')&&JSON.parse(localStorage.getItem('user'))
//   const params = {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       "Authorization": "Bearer " + userData.accessToken
//     },
//     body: JSON.stringify(body)
//   };
//   const res = await fetch(config.baseURL + path,params);
//   const data = await res.json();
//   console.log(data)
//   return { statusCode: res.status, data };
// }
const blockUsersRequest = async (path,body) =>{
  const userData = localStorage.getItem('user')&&JSON.parse(localStorage.getItem('user'))
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + userData.accessToken
    },
    body: JSON.stringify(body)
  };
  const res = await fetch(config.baseURL + path,params);
  const data = await res.json();
  return { statusCode: res.status, data };
}
const GetSupportData = async (path)=>{
  const userData = localStorage.getItem('user')&&JSON.parse(localStorage.getItem('user'))
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + userData.accessToken
    },
  };
  const res = await fetch(config.baseURL + path,params);
  const data = await res.json();
  console.log(data)
  return data;
}

const getRequest = async (path) =>{
  const userData = localStorage.getItem('user')&&JSON.parse(localStorage.getItem('user'))
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + userData.accessToken
    },
  };
  const res = await fetch(config.baseURL + path,params);
  const data = await res.json();
  return data
}
const DashboardData = async (path)=>{
  const userData = localStorage.getItem('user')&&JSON.parse(localStorage.getItem('user'))
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + userData.accessToken
    },
  };
  const res = await fetch(config.baseURL + path,params);
  const data = await res.json();
  console.log(data)
  return data;
}

// const getUserById = async (path,body) =>{
//     const userData = localStorage.getItem('user')&&JSON.parse(localStorage.getItem('user'))
//     const params = {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         "Authorization": "Bearer " + userData.accessToken
//       },
//       body: JSON.stringify(body)
//     };
//     const res = await fetch(config.baseURL + path,params);
//     const data = await res.json();
//     console.log(data);
//   return { statusCode: res.status, data };
// }
// const deleteUser = async(path,body)=>{
//       const userData = localStorage.getItem('user')&&JSON.parse(localStorage.getItem('user'))
//       const params = {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           "Authorization": "Bearer " + userData.accessToken
//         },
//         body: JSON.stringify(body)
//       };
//       const res = await fetch(config.baseURL + path,params);
//       const data = await res.json();
//       console.log(data);
//     return { statusCode: res.status, data };
// }
export const Api = {
  postRequest,
  listUsersRequest,
  GetSupportData,
  blockUsersRequest,
  DashboardData,
  getRequest,
  // getUserById,
  // getUserPlanDetails,
  // deleteUser,
};
