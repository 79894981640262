import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo/ClevroneLogo.png';
import cross from '../../assets/images/matiral-img/cross.png';
import './login.css';
import { style } from './style';
import { Api } from '../../api/Api';
import { toast } from 'react-toastify';
import Checkbox from '@mui/material/Checkbox';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate, Navigate } from 'react-router-dom';
const Login = () => {
  const [remember, setRemember] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [isEmailShowError, setIsEmailShowError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [status, setStatus] = useState();
  let navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    email: null,
    password: null,
  });
  useEffect(() => {
    const rememberedata = JSON.parse(localStorage.getItem('rememberemail'));
    if (rememberedata) {
      setLoginData({
        ...loginData,
        email: rememberedata?.email,
        password: rememberedata?.password,
      });
    } else {
      console.log('null');
    }
  }, []);
  const onEmailSet = (e) => {
    setLoginData({ ...loginData, email: e.target.value });
  };
  const onPasswordSet = (e) => {
    setLoginData({ ...loginData, password: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    let payload = {
      email: loginData?.email.toLowerCase(),
      password: loginData?.password,
    };
    const { statusCode, data } = await Api.postRequest('admin/login', payload);
    const res = JSON.parse(data);
    if(res?.status==1&&statusCode===200){
      navigate('/dashboard')
    }
    console.log('res', res, statusCode);
    setStatus(res.status);
  };
  return (
    <>
      {localStorage.getItem('user') && <Navigate to="/dashboard" replace />}
      <Box className="login__background">
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6} className="login__gridItem">
              <img src={logo} alt="logo" className="login__Clevrone_logo" />
            </Grid>
            <Grid item xs={6} className="login__gridContentItem">
              <Box className="login__gridContentBox">
                <Typography style={style.contentTitle}>
                  Great have you back!
                </Typography>
                <form onSubmit={onSubmit}>
                  <Box className="login__d-flex">
                    <Box>
                      <label htmlFor="Password" className="login__label">
                        Email
                      </label>
                      <Box className="login_position_relative login_error_img_position ">
                        <input
                          type="email"
                          placeholder="Enter your email"
                          className="login__input"
                          value={loginData.email}
                          onChange={onEmailSet}
                          required
                        />
                        {isEmailShowError && (
                          <img
                            src={cross}
                            alt={'wrong'}
                            className="login_wrong_close_img"
                          />
                        )}
                      </Box>
                    </Box>
                    <Box>
                      {isEmailShowError && (
                        <div className="login__error">
                          <div className="login__error_msgBody">
                            <p className="login__error_text">
                              Sorry, enter valid email
                            </p>
                          </div>
                        </div>
                      )}
                    </Box>
                  </Box>
                  <Box className="login__d-flex">
                    <Box>
                      <label htmlFor="Password" className="login__label">
                        Password
                      </label>
                      <Box className="login_position_relative login_error_img_position ">
                        <input
                          type={showPassword ? 'text' : 'password'}
                          name="Password"
                          placeholder="Enter your paassword"
                          className="login__input"
                          value={loginData.password}
                          onChange={onPasswordSet}
                          required
                        />
                        <Box
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? (
                            <VisibilityOffOutlinedIcon className="login__password_show_btn" />
                          ) : (
                            <VisibilityOutlinedIcon className="login__password_show_btn" />
                          )}
                        </Box>
                        {/* {isShowError && (
                          <img
                            src={cross}
                            alt={"wrong"}
                            className="login_wrong_close_img"
                          />
                        )} */}
                      </Box>
                    </Box>
                    <Box>
                      {isShowError && (
                        <div className="login__error">
                          <div className="login__error_msgBody">
                            <p className="login__error_text">
                              Sorry, enter valid password
                            </p>
                          </div>
                        </div>
                      )}
                    </Box>
                  </Box>
                  <Box className="login__alignItems">
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkbox"
                            sx={{
                              '& .MuiSvgIcon-root': { fontSize: 30 },
                              color: 'white',
                            }}
                            onChange={(e) => setRemember(!remember)}
                          />
                        }
                        label="Remember me"
                      />
                    </Box>
                  </Box>
                  <Box className="login__SIGN_IN_btn">
                    {status === 1 ? (
                      <Button type="submit" style={style.btn}>
                        SIGN IN
                      </Button>
                    ) : (
                      <Button type="submit" style={style.btn}>
                        SIGN IN
                      </Button>
                    )}
                  </Box>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default Login;









