import { useEffect, useState } from 'react';
import { Api } from '../../api/Api';
import Layout from "../../Component/Layout";
import { Box } from "@mui/material";
import TableBody from "@material-ui/core/TableBody";
import MuiTableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Table } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const TableCell = withStyles({
    root: {
      borderBottom: "none",
      color: "rgba(255, 255, 255, 0.4)",
      textAlign: "center",
    },
  })(MuiTableCell);
  const TableHeaderCell = withStyles({
    root: {
      borderBottom: "none",
      fontSize: 19,
      color: "white",
      fontWeight: "bold",
      backgroundColor: "#272E42",
      textAlign: "center",
    },
  })(MuiTableCell);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
      overflowX: "auto",
      // borderRadius:"10px"
    },
    table: {
      minWidth: 650,
      backgroundColor: "#020922",
    },
  }));
const NetworkMap = () => {
  const [tableData, setableData] = useState([]);
  const classes = useStyles();
  const listUserdata = async () => {
    const data = await Api.getRequest('admin/listorg');
    // console.log(data.data.docs);
    setableData(data.data.docs);
  };

  useEffect(() => {
    listUserdata();
  }, []);
  //   console.log(tableData);
  return (
    <>
      <Layout>
      <Box sx={{ color: "#fff" }}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableHeaderCell sx={{ fontWeight: 'bold' }} align="center">
                  Icon
                </TableHeaderCell>
                <TableHeaderCell sx={{ fontWeight: 'bold' }} align="center">
                  Id
                </TableHeaderCell>
                <TableHeaderCell sx={{ fontWeight: 'bold' }} align="center">
                  Email
                </TableHeaderCell>
                <TableHeaderCell sx={{ fontWeight: 'bold' }} align="center">
                  Created At
                </TableHeaderCell>
                <TableHeaderCell sx={{ fontWeight: 'bold' }} align="center">
                  Updated At
                </TableHeaderCell>
                <TableHeaderCell sx={{ fontWeight: 'bold' }} align="center">
                  Last Login Time
                </TableHeaderCell>
                </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, ind) => (
                <TableRow
                  key={row._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="center">
                    <img src={row.profilepic} width="50px" />
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.id}
                  </TableCell>
                  <TableCell align="center">{row.email}</TableCell>
                  <TableCell align="center">{row.createdAt}</TableCell>
                  <TableCell align="center">{row.updatedAt}</TableCell>
                  <TableCell align="center">{row.lastLoginTime}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
      </Box>
    </Layout>
    </>
  );
};
export default NetworkMap;
