import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo/clevrone - logo.png";
// import cloudComputing from "../../assets/images/side-bar-img/cloud-computing.png";
// import globe from "../../assets/images/side-bar-img/globe.png";
// import home from "../../assets/images/side-bar-img/home.png";
// import lock from "../../assets/images/side-bar-img/lock.png";
// import settings from "../../assets/images/side-bar-img/settings.png";
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import TokenIcon from '@mui/icons-material/Token';
import SettingsIcon from '@mui/icons-material/Settings';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});


const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Layout = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [path, setPath] = useState()

  // if (localStorage.getItem('user')) {
  //   navigate()
  // } else {
  //   navigate('/')
  // }
  
  

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let handalClick = () => {
    localStorage.removeItem('user');
    navigate('/')

  }
  const [anchorElManu, setAnchorElManu] = useState(null);
  const openAvatarManu = Boolean(anchorElManu);
  const handleClickAvatarManu = (event) => {
    setAnchorElManu(event.currentTarget);
  };
  const handleCloseAvatarManu = () => {
    setAnchorElManu(null);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} className="header">
        <Toolbar className="header-item">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              marginRight: 5,
              ...(!open && { display: "none" }),
            }}
          >
            {/* Mini variant drawer */}
          </Typography>
          <IconButton
            onClick={handleClickAvatarManu}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={anchorElManu ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={anchorElManu ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
          </IconButton>
        </Toolbar>
        <Menu
          anchorEl={anchorElManu}
          id="account-menu"
          open={openAvatarManu}
          onClose={handleCloseAvatarManu}
          onClick={handleCloseAvatarManu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem>
            <Avatar /> Profile
          </MenuItem>
          <MenuItem>
            <Avatar /> My account
          </MenuItem>
          <Divider />
          <MenuItem>
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            Add another account
          </MenuItem>
          {/* <MenuItem>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem> */}
          <MenuItem onClick={handalClick}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: "#020922",
            color: "#fff",
            borderRight: "1px solid #737373",
          },
        }}
      >
        <DrawerHeader>
          <img src={Logo} width="80%" alt="" />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon sx={{ color: "#fff" }} />
            ) : (
              <ChevronLeftIcon sx={{ color: "#fff" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem
            key={"DASHBOARD"}
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 0 : "auto",
                  justifyContent: "center",
                }}
              >
                <DashboardIcon color="info" sx={{marginX:2}} />
              </ListItemIcon>
              <ListItemText
                primary={"DASHBOARD"}
                sx={{ opacity: open ? 1 : 0 }}
                className="slide-bar-font-size"
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={"ORGANIZATIONS"}
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/organizations");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 0 : "auto",
                  justifyContent: "center",
                }}
              >
                <GroupsIcon color="info" sx={{marginX:2}} />
              </ListItemIcon>
              <ListItemText
                primary={"ORGANIZATIONS"}
                sx={{ opacity: open ? 1 : 0 }}
                className="slide-bar-font-size"
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={"USET LISTS"}
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/userlist");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 0 : "auto",
                  justifyContent: "center",
                }}
              >
                <PersonIcon color="info" sx={{marginX:2}} />
              </ListItemIcon>
              <ListItemText
                primary={"USER LISTS"}
                sx={{ opacity: open ? 1 : 0 }}
                className="slide-bar-font-size"
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={"SUPPORT TOKENS"}
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/supporttokens");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 0 : "auto",
                  justifyContent: "center",
                }}
              >
                <TokenIcon color="info" sx={{marginX:2}} />
              </ListItemIcon>
              <ListItemText
                primary={"SUPPORT TOKENS"}
                sx={{ opacity: open ? 1 : 0 }}
                className="slide-bar-font-size"
              />
            </ListItemButton>
          </ListItem>
          {/* <ListItem
            key={"SETTINGS"}
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/setting");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 0 : "auto",
                  justifyContent: "center",
                }}
              >
                <SettingsIcon color="info" sx={{marginX:2}} />
              </ListItemIcon>
              <ListItemText
                primary={"SETTINGS"}
                sx={{ opacity: open ? 1 : 0 }}
                className="slide-bar-font-size"
              />
            </ListItemButton>
          </ListItem> */}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {props.children}
      </Box>
    </Box>
  );
};

export default Layout;
