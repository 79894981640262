import React from 'react';
import { Navigate, Outlet} from "react-router-dom";
import { useAuth } from '../../AuthProvider';
const Outlets = () => {
    const {authUser} = useAuth()
    console.log(authUser)
  return (
    
    authUser
        ?
        <Outlet/>
        :
        <Navigate to='/' replace/>
    
  )
}

export default Outlets