import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { Api } from '../../api/Api';
import Layout from '../../Component/Layout';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    color: 'rgba(255, 255, 255, 0.4)',
    textAlign: 'center',
  },
})(MuiTableCell);
const TableHeaderCell = withStyles({
  root: {
    borderBottom: 'none',
    fontSize: 19,
    color: 'white',
    fontWeight: 'bold',
    backgroundColor: '#272E42',
    textAlign: 'center',
  },
})(MuiTableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    // borderRadius:"10px"
  },
  table: {
    minWidth: 650,
    backgroundColor: '#020922',
  },
}));
const AccessAnalysis = () => {
  const classes = useStyles();
  const [open, setOpen] =useState(false);
  const [selected, setSelected] = useState();
const handleClickOpen = (id) => {
  setSelected(id)
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};
  const [listUserData, setListUserData] = useState([]);
  const listUserGet = async () => {
    const data = await Api.listUsersRequest('admin/listusers');
    console.log(data);
    setListUserData(data.data.data.docs);
  
    // userPlanDetails();
  };
  useEffect(() => {
    listUserGet();
  }, []);
  // const userPlanDetails = async () => {
  //   const data = await Api.getUserPlanDetails('admin/getuserplandetails',{userid:listUserData.id,password:'CLEVR0NE@!@#$%'});
  //   console.log(data);
  // };
  const blockUserData = async()=>{
    
    const data = await Api.blockUsersRequest("admin/blockuser",{userid:selected});
    console.log(data);
    if(data){
      await listUserGet()
    }
    handleClose()
  }
  // const idToGetUser = async () => {
  //   const data = await Api.getUserById('ldap/get-user', {
  //     userid: listUserData.id,
  //     childuserid: 'childuserid',
  //   });
  //   console.log(data);
  // };
  // const userDelete = async (userid,roleId) => {
  //     const data = await Api.deleteUser("users/delete-role-by-id",{userid,roleId});
  //     console.log(data);
  // }
  return (
    <>
      <Layout>
        <Box sx={{ color: '#fff' }}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableHeaderCell>User Name</TableHeaderCell>
                  <TableHeaderCell>Id</TableHeaderCell>
                  <TableHeaderCell>Company</TableHeaderCell>
                  <TableHeaderCell>Email</TableHeaderCell>
                  <TableHeaderCell>CreatedAt</TableHeaderCell>
                  <TableHeaderCell>Action</TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listUserData.map((user) => (
                  <TableRow
                    key={user._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.id}</TableCell>
                    <TableCell>{user.company}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.createdAt}</TableCell>
                    <TableCell style={{display: 'flex', justifyContent: 'space-around'}}>
                      <Button variant='contained' sx={{
                        '&:disabled':{
                          backgroundColor: 'red',
                          color: 'white',
                        }
                      }} disabled={user.userStatus !== "1"} color='info' onClick={()=>
                        handleClickOpen(user.id)
                      }
                      >{user.userStatus !== "1"?"UnBlock":"Block"}</Button>
                      {/* <Button variant='contained' color='error'>Delete</Button> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
        </Box>
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{"& .MuiPaper-root":{backgroundColor:"#272E42",color:"#fff"}}}
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to block this account?"}
        </DialogTitle>
        
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={()=>blockUserData()} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      </Layout>
    </>
  );
};
export default AccessAnalysis;