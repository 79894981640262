import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './Screens/Dashboard';
import NetworkMap from './Screens/NetworkMap';
import AccessAnalysis from './Screens/AccessAnalysis';
import CloudInfo from './Screens/CloudInfo';
import Setting from './Screens/Setting';
import Login from './Screens/Login';
import Outlets from './Screens/ReactOutlet/Outlets';
import { AuthProvider } from './AuthProvider';
import NotFound from './Screens/NotFound';
function App() {
  return (
    <div className="App">
     
          <Routes>
          <Route  path="/" element={<Login />} />
          <Route  path="*" element={<NotFound />} />

          {/* <Route path='*' element={<NotFound/>}></Route> */}
            <Route element={<Outlets />}>
              <Route  path="/dashboard" element={<Dashboard />} />
              <Route  path="/organizations" element={<NetworkMap />} />
              <Route  path="/userlist" element={<AccessAnalysis />} />
              <Route  path="/supporttokens" element={<CloudInfo />} />
              <Route  path="/setting" element={<Setting />} />
            </Route>
          </Routes>
            </div>
  );
}

export default App;
