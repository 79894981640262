import React, { useCallback, useMemo, useState, useEffect } from "react";
import { Api } from "../../api/Api";
import "./CloudInfo.css";
import Layout from "../../Component/Layout";
import { Box } from "@mui/material";
import TableBody from "@material-ui/core/TableBody";
import MuiTableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Table } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    color: "rgba(255, 255, 255, 0.4)",
    textAlign: "center",
  },
})(MuiTableCell);
const TableHeaderCell = withStyles({
  root: {
    borderBottom: "none",
    fontSize: 19,
    color: "white",
    fontWeight: "bold",
    backgroundColor: "#272E42",
    textAlign: "center",
  },
})(MuiTableCell);
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
    // borderRadius:"10px"
  },
  table: {
    minWidth: 650,
    backgroundColor: "#020922",
  },
}));

const SupportTicket = () => {
  const [data, setdata] = useState([]);
  const classes = useStyles();

  const getData = async () => {
    const { data } = await Api.GetSupportData("admin/listsupporttickets");
    setdata(data.docs);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout>
      <Box sx={{ color: "#fff" }}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableHeaderCell>ID</TableHeaderCell>
                <TableHeaderCell>Description</TableHeaderCell>
                <TableHeaderCell>Title</TableHeaderCell>
                <TableHeaderCell>User ID</TableHeaderCell>
                <TableHeaderCell>Problem Files</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                <>
                  {data.map((item) => (
                    <TableRow
                      key={item.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.title}</TableCell>
                      <TableCell>{item.userid}</TableCell>
                      <TableCell>
                        <Box
                          component="img"
                          sx={{
                            height: 100,
                            width: 110,
                            maxHeight: { xs: 70, md: 100 },
                            maxWidth: { xs: 70, md: 100 },
                          }}
                          src={item?.problemfiles[0]}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={5}>
                    <h2 style={{ color: "white", textAlign: "center" }}>
                      No Data Found
                    </h2>
                  </td>
                </tr>
              )}
            </TableBody>
          </Table>
      </Box>
    </Layout>
  );
};

export default SupportTicket;