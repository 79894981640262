import { Box } from "@mui/system";
import Layout from "../../Component/Layout";
const Setting = () => {
  return (
    <>
      <Layout>
        <Box sx={{ color: "#fff" }}>Setting</Box>
      </Layout>
    </>
  );
};
export default Setting;
